body,
html {
    font-family: 'Open Sans', sans-serif;
}
button,
.pointer {
    cursor: pointer;
}
h1,
h2,
h3 {
    color: rgba(0, 0, 0, 0.65) !important;
}
.uppercase {
    text-transform: uppercase;
}
.ml {
    margin-left: 8px !important;
}
.ml-sm {
    margin-left: 5px !important;
}
.mr {
    margin-right: 8px !important;
}
.mt {
    margin-top: 8px !important;
}
.mb {
    margin-bottom: 8px !important;
}
.pl {
    padding-left: 8px !important;
}
.pr {
    padding-right: 8px !important;
}
.pt {
    padding-top: 8px !important;
}
.pb {
    padding-bottom: 8px !important;
}
.mb-sm {
    margin-bottom: 4px;
}
.fw {
    width: 100%;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.float-left {
    float: left;
}
.float-right {
    float: right;
}
.clear {
    clear: both;
}
//API test CSS
pre {
    background: #eee;
    padding: 20px 10px;
    border-radius: 3px;
    border: 1px solid #bbb;
    font-size: 11px !important;
    max-height: 650px;
}
.max-400 {
    max-height: 750px;
    overflow: auto;
}
.active-list {
    background: #eee;
}
.input-group {
    max-height: 400px;
    overflow: auto;
    box-shadow: 5px 5px 5px #eee;
    padding: 5px 10px;
}
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    left: 8px;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.output-img {
    width: 120px;
    cursor: pointer;
    border-radius: 10px;
    margin: 20px;
}

#map {
    height: 800px;
    width: 100%;
    border: 1px solid #228282;
    border-radius: 3px;
    margin-bottom: 30px;
    margin-top: 10px;
}
.pageLayout {
    min-height: 100vh;
    width: 100vw;
}

.FormWrapper {
    padding: 60px 20px;
    max-width: 350px;
    margin: 20px auto;
}
.PageProductSection {
    border-left: 1px solid black;
    min-height: 100vh;
    background: url('/img/download.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat-x;
    -webkit-animation: backgroundScroll 1500s linear 1;
    -webkit-animation-fill-mode: forwards;
    animation: backgroundScroll 1500s linear 1;
    animation-fill-mode: forwards;
}

@keyframes backgroundScroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -48000px 0px;
    }
}
.fx-container {
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
}
.logout-btn {
    position: absolute;
    top: 20px;
    right: 25px;
}
.cbf-toggle {
    position: absolute;
    top: 50px;
    right: 25px;
}
.layer-box {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid #aaa;
    max-width: 550px;
}
.fmg-logo {
    width: 100px;
    margin-bottom: 20px;
}
.falcon-alpha-logo-login {
    margin-top: -100px; // negative value as image itself as large margin
    width: 300px;
    margin-bottom: -50px; // negative value as image itself as large margin
}
.ai-logo-login {
    width: 150px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.ai-logo {
    margin-top: -100px; // negative value as image itself as large margin
    width: 300px;
    position: relative;
    margin-bottom: -100px; // negative value as image itself as large margin
    // right: 100px;
    // top: -15px;
}
.hide {
    display: none;
}
.relative {
    position: relative;
}
.download-btn {
    .mt;
    width: 100%;
}

table td {
    font-size: 13px !important;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
}
*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #999;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}
.logo-popover {
    width: 400px;
}

#attribute-overlay {
    padding: 0px;
    border-radius: 0px;
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    left: 12px;
    top: 5px;
    border-radius: 3px;
    border: 1px solid #aaa !important;
}
.attribute-table {
    min-width: 150px;
}
.attribute-table th,
.attribute-table td {
    padding: 5px;
    min-width: 50px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 12px;
    text-transform: capitalize;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #cccccc;
    bottom: 12px;
    font-size: 13px;
    left: -50px;
    min-width: 120px;
}
.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}
.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.oblique-slider {
    width: 400px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px;
    position: relative;
    height: 220px;
    cursor: pointer;
}
.oblique-slider img {
    left: 30px;
    position: absolute;
    width: 320px;
    height: 200px;
}
.oblique-slider p {
    right: 0px;
    top: 0;
    font-size: 18px;
    padding: 5px 8px;
    background: rgba(0, 0, 0, 0.65);
    color: #fff;
    position: absolute;
}
.image-zoomed-view {
    position: relative;
    top: 0;
}
.image-zoomed-view .ant-modal-content {
    background: transparent;
    box-shadow: none;
    position: relative;
}
.image-zoomed-view img {
    left: 0;
    top: 0;
    max-width: 100%;
    max-height: 100vh;
    vertical-align: middle;
    // position: relative;
    // max-width: 1000;
}
.react-transform-component {
    margin: 0 auto !important;
}
.next-prev-btns {
    font-size: 20px;
}
.next-prev-btns .next {
    position: absolute !important;
    top: 45%;
    right: 2px;
}
.next-prev-btns .prev {
    position: absolute !important;
    top: 45%;
    left: 2px;
}
.dock-btn {
    position: absolute !important;
    top: 3px;
    right: 3px;
}
.output-container {
    width: 100%;
    min-height: 430px;
    padding: 20px;
    overflow: auto;
    border: 1px solid #ccc;
}

.export-btn {
    position: absolute !important;
    top: -40px;
    left: 145px;
}
.export-btn.view {
    left: 225px;
}
.next-prev-btns.oblique {
    top: 50%;
    z-index: 100;
}
.next-prev-btns.oblique .next {
    right: 0 !important;
    left: initial !important;
    font-size: 30px;
    color: #fff;
    z-index: 100;
}

.next-prev-btns.oblique .prev {
    left: 0 !important;
    font-size: 30px;
    z-index: 100;
    right: initial !important;
    color: #fff;
}
.oblique-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
    font-size: 20px;
    width: 80px;
    text-align: center;
    background-color: #fff;
    padding: 8px 10px;
}
